import React from "react";
import useApi, { Api } from "../api";
import { Store } from "../customers/customer";
import StoreList from "./StoreList";

interface StoresParams {
  customerId: string;
  count: number | undefined;
}

const loadData = async (
  api: Api,
  aborted: () => boolean,
  customerId: string,
  setStores: React.Dispatch<Store[]>,
): Promise<void> => {
  const storeResponse = await api.store.list(customerId);

  if (!aborted()) {
    setStores(storeResponse.items);
  }
};

const Stores = ({ customerId, count }: StoresParams) => {
  const [stores, setStores] = React.useState();

  useApi((api, aborted) => loadData(api, aborted, customerId, setStores), [customerId, setStores]);

  return <StoreList stores={stores} count={count} />;
};

export default Stores;
