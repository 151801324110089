import { useEffect } from "react";
import { useAuth0 } from "./react-auth0-wrapper";
import { Warehouse, Store, Operation, Customer, HubEvent } from "./customers/customer";

export interface ListResponse<T> {
  items: T[];
}

export interface ItemResponse<T> {
  item: T;
}

export interface Api {
  customer: {
    list: (query: string) => Promise<ListResponse<Customer>>;
    show: (customerId: string) => Promise<ItemResponse<Customer>>;
  };
  events: {
    entity: (
      entityType: string,
      entityId: string,
      afterSequence?: number,
      pageSize?: number,
    ) => Promise<ListResponse<HubEvent>>;
  };
  operations: (customerId: string, storeId: string) => Promise<ListResponse<Operation>>;
  warehouses: (customerId: string) => Promise<ListResponse<Warehouse>>;
  store: {
    list: (customerId: string) => Promise<ListResponse<Store>>;
    show: (customerId: string, storeId: string) => Promise<ItemResponse<Store>>;
  };
}

export interface ApiCallback {
  (api: Api, aborted: () => boolean): Promise<void>;
}

interface CachedResponse {
  expiresAfter: Date;
  value: unknown;
}

const cache: { [key: string]: CachedResponse } = {};

const makeRequest = async <T>(
  token: string,
  endpoint: string,
  method: string = "GET",
  body?: object,
  headers = {},
) => {
  if (method === "GET") {
    const cachedResponse = cache[endpoint];
    if (cachedResponse) {
      if (cachedResponse.expiresAfter > new Date()) {
        return cachedResponse.value as T;
      } else {
        delete cache[endpoint];
      }
    }
  }

  const requestHeaders = new Headers(headers);
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Accept", "application/json");
  requestHeaders.set("Authorization", `Bearer ${token}`);

  const options: RequestInit = { method, headers: requestHeaders };
  if (body) {
    options["body"] = JSON.stringify(body);
  }

  try {
    const result = await fetch("/api" + endpoint, options);
    const content = await result.json();

    if (result.status === 200) {
      cache[endpoint] = {
        expiresAfter: new Date(new Date().getTime() + 60000),
        value: content,
      };

      return content as T;
    }
    console.log(result);
    throw new Error(content);
  } catch (error) {
    console.error(error);
    throw new Error("Could not make request");
  }
};

const createApi = (token: string): Api => {
  return {
    customer: {
      list: query => makeRequest<ListResponse<Customer>>(token, `/customers?q=${query}`),
      show: (customerId: string) =>
        makeRequest<ItemResponse<Customer>>(token, `/customer/${customerId}`),
    },
    events: {
      entity: (entityType, entityId, afterSequence, pageSize) =>
        makeRequest<ListResponse<HubEvent>>(
          token,
          `/events/entity/${entityType}/${entityId}?${Object.entries({
            afterSequence,
            pageSize,
          })
            .filter(([, value]) => value)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`,
        ),
    },
    operations: (customerId: string, storeId: string) =>
      makeRequest<ListResponse<Operation>>(
        token,
        `/customer/${customerId}/store/${storeId}/operations`,
      ),
    warehouses: (customerId: string) =>
      makeRequest<ListResponse<Warehouse>>(token, `/customer/${customerId}/warehouses`),
    store: {
      list: (customerId: string) =>
        makeRequest<ListResponse<Store>>(token, `/customer/${customerId}/stores`),
      show: (customerId: string, storeId: string) =>
        makeRequest<ItemResponse<Store>>(token, `/customer/${customerId}/store/${storeId}`),
    },
  };
};

const invokeWithApi = async (
  timeoutHandler: number,
  controller: AbortController,
  getTokenSilently: (o?: GetTokenSilentlyOptions | undefined) => Promise<string | undefined>,
  callback: ApiCallback,
): Promise<void> => {
  try {
    const token = await getTokenSilently();
    if (!token) {
      throw new Error("Could not get token");
    }

    const api = createApi(token);
    await callback(api, () => controller.signal.aborted);
  } finally {
    clearTimeout(timeoutHandler);
  }
};

const useApi = (callback: ApiCallback, deps: readonly any[] | undefined) => {
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
    }, 10000);

    invokeWithApi(timeout, controller, getTokenSilently, callback);

    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, deps);
};

export default useApi;
