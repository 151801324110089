// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-wrapper";
import PrivateNavBar from "./PrivateNavBar";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return !isAuthenticated ? (
    <button onClick={() => loginWithRedirect({})}>Log in</button>
  ) : (
    <PrivateNavBar />
  );
};

export default NavBar;
