// src/App.js

import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import styled from "styled-components";
import NavBar from "./components/NavBar";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import Customers from "./customers/Customers";
import CustomerDetails from "./customers/CustomerDetails";
import StoreDetails from "./stores/StoreDetails";
import OrderEvents from "./orders/OrderEvents";
import CustomerEvents from "./events/CustomerEvents";

const navWidth = "13";

const LeftHeader = styled.header`
  position: fixed;
  top: 0;
  width: ${navWidth}rem;
  height: 100%;
`;

const Details = styled.div`
  height: 100%;
  overflow-y: auto;
  margin: 0 0 0 ${navWidth}rem;
  padding: 1rem;
`;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LeftHeader>
          <NavBar />
        </LeftHeader>
        <Details>
          <Switch>
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/customers" exact component={Customers} />
            <PrivateRoute path="/customers/:customerId" exact component={CustomerDetails} />
            <PrivateRoute
              path="/customers/:customerId/stores/:storeId"
              exact
              component={StoreDetails}
            />
            <PrivateRoute path="/events/:entityType/:entityId" exact component={CustomerEvents} />
            <PrivateRoute path="/events/order/:orderId" exact component={OrderEvents} />
          </Switch>
        </Details>
      </BrowserRouter>
    </div>
  );
}

export default App;
