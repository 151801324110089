import React from "react";
import styled from "styled-components";
import { Operation } from "../customers/customer";
import OperationListItem from "./OperationListItem";

interface OperationListProps {
  operations?: Operation[];
}

const TableHeader = styled.th`
  text-align: left;
`;

const OperationList = ({ operations }: OperationListProps) => {
  return (
    <div>
      <h3>Import Operations</h3>
      <table>
        <thead>
          <tr>
            <TableHeader>Status</TableHeader>
            <TableHeader>Started</TableHeader>
            <TableHeader>Duration</TableHeader>
            <TableHeader>Request Id</TableHeader>
            <TableHeader>Orders</TableHeader>
          </tr>
        </thead>
        <tbody>
          {operations &&
            operations.map(x => <OperationListItem key={x.operationId} operation={x} />)}
        </tbody>
      </table>
    </div>
  );
};

export default OperationList;
