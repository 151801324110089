import React, { ReactNode } from "react";
import styled from "styled-components";

interface ActionListProps {
  direction?: "horizontal" | "vertical";
  children: ReactNode;
}

const ActionListContainer = styled.div`
  display: flex;
  flex-direction: ${(props: { direction: string }) => props.direction};
  align-items: start;
`;

const ActionList = ({ direction, children }: ActionListProps) => {
  return (
    <ActionListContainer direction={direction === "vertical" ? "column" : "row"}>
      {children}
    </ActionListContainer>
  );
};

export default ActionList;
