export interface StoreDetails {
  title: string;
  image: string;
}

const storeMap: { [key: number]: StoreDetails } = {
  0: {
    title: "Miva",
    image: "miva",
  },
  1: {
    title: "Ebay",
    image: "ebay",
  },
  2: {
    title: "Yahoo",
    image: "yahoo",
  },
  3: {
    title: "ShopSite",
    image: "shopsite",
  },
  4: {
    title: "MarketplaceAdvisor",
    image: "marketplaceadvisor",
  },
  5: {
    title: "osCommerce",
    image: "oscommerce",
  },
  6: {
    title: "ProStores",
    image: "prostores",
  },
  7: {
    title: "ChannelAdvisor",
    image: "channeladvisor",
  },
  8: {
    title: "Infopia",
    image: "infopia",
  },
  9: {
    title: "CreLoaded",
    image: "creloaded",
  },
  10: {
    title: "Amazon",
    image: "amazon",
  },
  11: {
    title: "XCart",
    image: "xcart",
  },
  12: {
    title: "OrderMotion",
    image: "ordermotion",
  },
  13: {
    title: "ZenCart",
    image: "zencart",
  },
  16: {
    title: "VirtueMart",
    image: "virtuemart",
  },
  17: {
    title: "ClickCartPro",
    image: "clickcartpro",
  },
  18: {
    title: "PayPal",
    image: "paypal",
  },
  19: {
    title: "Volusion",
    image: "volusion",
  },
  20: {
    title: "NetworkSolutions",
    image: "networksolutions",
  },
  21: {
    title: "Magento",
    image: "magento",
  },
  22: {
    title: "OrderDynamics",
    image: "orderdynamics",
  },
  23: {
    title: "SellerVantage",
    image: "sellervantage",
  },
  24: {
    title: "WebShopManager",
    image: "webshopmanager",
  },
  25: {
    title: "AmeriCommerce",
    image: "americommerce",
  },
  26: {
    title: "CommerceInterface",
    image: "commerceinterface",
  },
  27: {
    title: "SearchFit",
    image: "searchfit",
  },
  28: {
    title: "GenericModule",
    image: "genericmodule",
  },
  29: {
    title: "ThreeDCart",
    image: "threedcart",
  },
  30: {
    title: "BigCommerce",
    image: "bigcommerce",
  },
  31: {
    title: "GenericFile",
    image: "genericfile",
  },
  32: {
    title: "Shopify",
    image: "shopify",
  },
  33: {
    title: "Etsy",
    image: "etsy",
  },
  34: {
    title: "NeweggMarketplace",
    image: "neweggmarketplace",
  },
  35: {
    title: "BuyDotCom",
    image: "buydotcom",
  },
  36: {
    title: "Sears",
    image: "sears",
  },
  37: {
    title: "SolidCommerce",
    image: "solidcommerce",
  },
  38: {
    title: "Brightpearl",
    image: "brightpearl",
  },
  39: {
    title: "OrderDesk",
    image: "orderdesk",
  },
  40: {
    title: "Cart66Lite",
    image: "cart66lite",
  },
  41: {
    title: "Cart66Pro",
    image: "cart66pro",
  },
  42: {
    title: "Shopp",
    image: "shopp",
  },
  43: {
    title: "Shopperpress",
    image: "shopperpress",
  },
  44: {
    title: "WPeCommerce",
    image: "wpecommerce",
  },
  45: {
    title: "Jigoshop",
    image: "jigoshop",
  },
  46: {
    title: "WooCommerce",
    image: "woocommerce",
  },
  47: {
    title: "ChannelSale",
    image: "channelsale",
  },
  48: {
    title: "Fortune3",
    image: "fortune3",
  },
  49: {
    title: "LiveSite",
    image: "livesite",
  },
  50: {
    title: "SureDone",
    image: "suredone",
  },
  51: {
    title: "Zenventory",
    image: "zenventory",
  },
  52: {
    title: "nopCommerce",
    image: "nopcommerce",
  },
  53: {
    title: "LimeLightCRM",
    image: "limelightcrm",
  },
  54: {
    title: "OpenCart",
    image: "opencart",
  },
  55: {
    title: "SellerExpress",
    image: "sellerexpress",
  },
  56: {
    title: "PowersportsSupport",
    image: "powersportssupport",
  },
  57: {
    title: "CloudConversion",
    image: "cloudconversion",
  },
  58: {
    title: "CsCart",
    image: "cscart",
  },
  59: {
    title: "PrestaShop",
    image: "prestashop",
  },
  60: {
    title: "LoadedCommerce",
    image: "loadedcommerce",
  },
  61: {
    title: "Choxi",
    image: "choxi",
  },
  62: {
    title: "Groupon",
    image: "groupon",
  },
  63: {
    title: "StageBloc",
    image: "stagebloc",
  },
  64: {
    title: "RevolutionParts",
    image: "revolutionparts",
  },
  65: {
    title: "InstaStore",
    image: "instastore",
  },
  66: {
    title: "OrderBot",
    image: "orderbot",
  },
  67: {
    title: "OpenSky",
    image: "opensky",
  },
  68: {
    title: "LemonStand",
    image: "lemonstand",
  },
  69: {
    title: "SparkPay",
    image: "sparkpay",
  },
  70: {
    title: "Odbc",
    image: "odbc",
  },
  71: {
    title: "Amosoft",
    image: "amosoft",
  },
  73: {
    title: "SellerCloud",
    image: "sellercloud",
  },
  74: {
    title: "InfiPlex",
    image: "infiplex",
  },
  75: {
    title: "Walmart",
    image: "walmart",
  },
  76: {
    title: "SellerActive",
    image: "selleractive",
  },
  78: {
    title: "GeekSeller",
    image: "geekseller",
  },
  79: {
    title: "Jet",
    image: "jet",
  },
  80: {
    title: "Manual",
    image: "manual",
  },
  82: {
    title: "Overstock",
    image: "overstock",
  },
  83: {
    title: "CommerceV3",
    image: "commercev3",
  },
  84: {
    title: "Zentail",
    image: "zentail",
  },
  85: {
    title: "Bonanza",
    image: "bonanza",
  },
};

const getStoreDetails = (storeType: number): StoreDetails => {
  return (
    storeMap[storeType] || {
      title: "Unknown",
    }
  );
};

export default getStoreDetails;
