import React from "react";
import useApi, { Api } from "../api";
import { Store } from "../customers/customer";
import StoreOperations from "../operations/StoreOperations";
import { RouteComponentProps } from "react-router";
import BreadCrumbs from "../components/BreadCrumbs";
import { Link } from "react-router-dom";
import StoreDetailsInfo from "./StoreDetailsInfo";

interface StoreParams {
  customerId: string;
  storeId: string;
}

const loadData = async (
  api: Api,
  aborted: () => boolean,
  customerId: string,
  storeId: string,
  setStore: React.Dispatch<Store>,
): Promise<void> => {
  const storeResponse = await api.store.show(customerId, storeId);

  if (!aborted()) {
    setStore(storeResponse.item);
  }
};

const StoreDetails = ({ match }: RouteComponentProps<StoreParams>) => {
  const { customerId, storeId } = match.params;
  const [store, setStore] = React.useState<Store>();

  useApi((api, aborted) => loadData(api, aborted, customerId, storeId, setStore), [
    customerId,
    storeId,
    setStore,
  ]);

  return (
    <div>
      <BreadCrumbs>
        <Link to="/customers">customers</Link> /{" "}
        <Link to={`/customers/${customerId}`}>{customerId}</Link> / stores / {storeId}
      </BreadCrumbs>
      {store && <StoreDetailsInfo store={store} />}
      <StoreOperations customerId={customerId} storeId={storeId} />
    </div>
  );
};

export default StoreDetails;
