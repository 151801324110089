import styled from "styled-components";

const CardTitle = styled.div`
  font-size: 20pt;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export default CardTitle;
