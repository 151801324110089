import React from "react";
import styled from "styled-components";
import storeTypes from "../storeTypes";
import { Store } from "../customers/customer";
import IdList from "../components/IdList";
import Card from "../components/Card";
import CardTitle from "../components/CardTitle";
import Tag from "../components/Tag";
import TagList from "../components/TagList";
import ActionList from "../components/ActionList";
import ActionLink from "../components/ActionLink";

interface StoreListItemProps {
  store: Store;
}

const StoreLogo = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`;

const StoreListItem = ({ store }: StoreListItemProps) => {
  const storeType = storeTypes(store.storeType);

  return (
    <Card>
      <CardTitle>
        <StoreLogo src={`/images/store-icons/${storeType.image}.png`} />
        {store.name}
      </CardTitle>

      <IdList items={{ "Store Identifier": store.uniqueIdentifier, "Store ID": store.storeId }} />

      <TagList>{store.removed && <Tag type="alert" title="removed" />}</TagList>

      <ActionList>
        <ActionLink to={`/customers/${store.customerId}/stores/${store.storeId}`}>
          Details
        </ActionLink>
      </ActionList>
    </Card>
  );
};
export default StoreListItem;
