import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface ActionLinkProps {
  to: string;
  children: ReactNode;
}

const LinkContainer = styled(Link)`
  display: flex;
  font-size: 12pt;
  padding: 0.4rem;
  background-color: #105b97;
  color: white;
  border: 0;
  border-radius: 0.2rem;
  text-decoration: none;

  &[disabled] {
    background-color: #666666;
    color: #ffffff;
  }
`;

const Text = styled.div`
  text-align: left;
  flex-grow: 1;
  padding: 0.2rem 0.4rem;
`;

const ActionLink = ({ to, children }: ActionLinkProps) => {
  return (
    <LinkContainer to={to}>
      <Text>{children}</Text>
    </LinkContainer>
  );
};

export default ActionLink;
