import React from "react";
import styled from "styled-components";
import { Customer } from "./customer";
import { Link } from "react-router-dom";

interface CustomerListItemProps {
  customer: Customer;
}

const CustomerRow = styled.div`
  display: flex;
  font-size: 24pt;
  align-items: center;
`;

const CustomerId = styled(Link)`
  text-align: right;
  padding: 1rem;
  margin-right: 1rem;
  background: #1b3251;
  color: #ffffff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border: solid 0.2rem;
  border-right: none;
  width: 9rem;
  text-decoration: none;
`;

const Details = styled.div`
  padding: 0 1rem;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;

  dl {
    display: flex;

    dt {
      font-size: 12pt;
      text-align: center;
      margin: 0 1rem;
    }
    dd {
      font-size: 20pt;
      text-align: center;
    }
  }
`;

const Name = styled.div`
  flex-shrink: 0;
  flex-grow: 1;

  a {
    color: black;
  }
`;

const Address = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  font-size: 12pt;
`;

const CustomerListItem = ({ customer }: CustomerListItemProps) => {
  return (
    <CustomerRow>
      <CustomerId
        to={`/customers/${customer.customerId}`}
        className="customerListItemSummary-border"
      >
        {customer.customerId}
      </CustomerId>
      <Name>
        <Link to={`/customers/${customer.customerId}`}>
          {customer.firstName} {customer.lastName}
        </Link>
      </Name>
      <Details className="customerListItemSummary-border">
        <Address>
          {customer.street1 ? <><div>{customer.street1}</div>
            <div>
              {customer.city}, {customer.state} {customer.zip}
            </div></> : <div></div>}
        </Address>
        <dl>
          <div>
            <dt>Warehouses</dt>
            <dd>{customer.warehouses || 0}</dd>
          </div>
          <div>
            <dt>Stores</dt>
            <dd>{customer.stores || 0}</dd>
          </div>
        </dl>
      </Details>
    </CustomerRow>
  );
};

export default CustomerListItem;
