import React from "react";
import styled from "styled-components";

const IconWrapper = styled.span`
  display: inline-block;
`;

interface IconProps {
  className?: string;
  name: string;
  iconStyle?: "fas" | "far" | "fal" | "fad";
  id?: string;
}

const Icon = ({ className, name, iconStyle = "fas", id }: IconProps) => (
  <IconWrapper className={className}>
    <i className={`${iconStyle} fa-${name}`} id={id} />
  </IconWrapper>
);

export default Icon;
