import React from "react";
import { RouteComponentProps } from "react-router";
import useApi, { Api } from "../api";
import { HubEvent } from "../customers/customer";
import EventListItem from "./EventListItem";
import styled from "styled-components";

interface CustomerEventsParams {
  entityType: "customer" | "operation" | "order" | "product";
  entityId: string;
}

const TableHeaderCell = styled.th`
  text-align: left;
  padding-right: 1rem;
`;

const loadData = async (
  api: Api,
  aborted: () => boolean,
  entityType: string,
  entityId: string,
  setEvents: React.Dispatch<HubEvent[]>,
  setLoading: React.Dispatch<boolean>,
): Promise<void> => {
  const eventsResponse = await api.events.entity(entityType, entityId);

  if (!aborted()) {
    setEvents(eventsResponse.items);
  }

  setLoading(false);
};

const CustomerEvents = ({ match }: RouteComponentProps<CustomerEventsParams>) => {
  const { entityType, entityId } = match.params;
  const [events, setEvents] = React.useState<HubEvent[]>([]);
  const [loading, setLoading] = React.useState(true);

  useApi((api, aborted) => loadData(api, aborted, entityType, entityId, setEvents, setLoading), [
    entityType,
    entityId,
    setEvents,
    setLoading,
  ]);

  return (
    <>
      <div>Events</div>
      <table>
        <thead>
          <tr>
            <TableHeaderCell>Sequence</TableHeaderCell>
            <TableHeaderCell>Version</TableHeaderCell>
            <TableHeaderCell>Timestamp</TableHeaderCell>
            <TableHeaderCell>Event</TableHeaderCell>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map(x => (
            <EventListItem key={x.sequence} event={x} entityType={entityType} />
          ))}
        </tbody>
      </table>
      {loading && <div>Loading...</div>}
    </>
  );
};

export default CustomerEvents;
