import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerList from "./CustomerList";

const Customers = () => (
  <Switch>
    <Route path="/customers/" exact component={CustomerList} />
  </Switch>
);

export default Customers;
