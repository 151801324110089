import React from "react";
import useApi, { Api } from "../api";
import OperationList from "./OperationList";
import { Operation } from "../customers/customer";

interface StoreOperationsProps {
  customerId: string;
  storeId: string;
}

const loadData = async (
  api: Api,
  aborted: () => boolean,
  customerId: string,
  storeId: string,
  setOperations: React.Dispatch<Operation[]>,
): Promise<void> => {
  const operationResponse = await api.operations(customerId, storeId);

  if (!aborted()) {
    setOperations(operationResponse.items);
  }
};

const defaultOperations: Operation[] = [];

const StoreOperations = ({ customerId, storeId }: StoreOperationsProps) => {
  const [operations, setOperations] = React.useState(defaultOperations);
  useApi((api, aborted) => loadData(api, aborted, customerId, storeId, setOperations), [
    customerId,
    storeId,
  ]);

  return <OperationList operations={operations} />;
};

export default StoreOperations;
