import React, { ReactNode } from "react";
import styled from "styled-components";

interface TagProps {
  type: "alert" | "success" | "warning";
  title?: string;
  children?: ReactNode;
}

const TagContainer = styled.span`
  border-radius: 1rem;
  border-style: solid;
  border-width: 1px;
  font-size: 10pt;
  padding: 0.2rem 1rem;

  &.alert {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;
  }

  &.success {
    border-color: #d4edda;
    background-color: #d4edda;
    color: #155724;
  }

  &.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
`;

const Tag = ({ type, title, children }: TagProps) => {
  return (
    <TagContainer className={type}>
      {title}
      {children}
    </TagContainer>
  );
};

export default Tag;
