import React from "react";
import styled from "styled-components";
// @ts-ignore
import uuidv3 from "uuid/v3";
import storeTypes from "../storeTypes";
import { Store } from "../customers/customer";
import IdList from "../components/IdList";
import Tag from "../components/Tag";
import TagList from "../components/TagList";
import ActionList from "../components/ActionList";
import ActionButton from "../components/ActionButton";
import { Link } from "react-router-dom";

// This is an arbitrary guid to use as the namespace for the v3 uuid
// THIS CANNOT CHANGE or else finding an order by customerid, storeid, and order number will break
const orderIdNamespace = "caca6dbc-f7af-43c8-afd2-147f91ba40cd";

interface StoreDetailsInfoProps {
  store: Store;
}

const Container = styled.div`
  display: flex;
`;
const StoreLogo = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`;

const Header = styled.div`
  margin: 0 0 1rem 0;
  flex: 1;
`;
const Title = styled.div`
  font-size: 24pt;
`;
const IdListWrapper = styled.div`
  margin: 0 1rem;
`;
const OrderIdLookup = styled.div`
  margin-bottom: 1rem;
  font-size: 12pt;
`;

const cleanGuidString = (x: string): string => x.replace(/[{}-]/g, "").toLowerCase();

const StoreDetailsInfo = ({ store }: StoreDetailsInfoProps) => {
  const [orderNumber, setOrderNumber] = React.useState("");
  const orderId =
    orderNumber &&
    cleanGuidString(
      uuidv3(`${store.customerId}~${store.storeId}~${orderNumber}`, orderIdNamespace),
    );

  const storeType = storeTypes(store.storeType);

  return (
    <>
      <Container>
        <Header>
          <Title>
            <StoreLogo src={`/images/store-icons/${storeType.image}.png`} />
            {store.name}
          </Title>
          <TagList>{store.removed && <Tag type="alert" title="removed" />}</TagList>
        </Header>

        <IdListWrapper>
          <IdList
            alignment="right"
            items={{ "Store Identifier": store.uniqueIdentifier, "Store ID": store.storeId }}
          />
        </IdListWrapper>

        <ActionList direction="vertical">
          <ActionButton onClick={() => alert("Not implemented yet")}>Remove</ActionButton>
        </ActionList>
      </Container>
      <OrderIdLookup>
        Find order id:{" "}
        <input
          type="text"
          value={orderNumber}
          onChange={evt => setOrderNumber(evt.target.value)}
          placeholder="Order number"
        />{" "}
        : <Link to={`/events/order/${orderId}`}>{orderId}</Link>
      </OrderIdLookup>
    </>
  );
};
export default StoreDetailsInfo;
