import React from "react";
import styled from "styled-components";

type IdListPropsAlignment = "left" | "right" | "center" | "inherit";

interface IdListProps {
  items: {
    [key: string]: string;
  };
  alignment?: IdListPropsAlignment;
}

const IdListContainer = styled.dl`
  margin-bottom: 0.4rem;
  text-align: ${(props: { alignment: IdListPropsAlignment }) => props.alignment};
`;

const IdLabel = styled.dt`
  font-size: 10pt;
  color: #999999;
`;

const IdValue = styled.dd`
  font-size: 12pt;
  padding-bottom: 0.5rem;
`;

const IdList = ({ items, alignment = "inherit" }: IdListProps) => {
  return (
    <IdListContainer alignment={alignment}>
      {Object.entries(items).map(([key, value]) => (
        <React.Fragment key={key}>
          <IdLabel>{key}</IdLabel>
          <IdValue>{value}</IdValue>
        </React.Fragment>
      ))}
    </IdListContainer>
  );
};

export default IdList;
