import React from "react";
import StoreListItem from "./StoreListItem";
import { Store } from "../customers/customer";

interface StoreListProps {
  stores?: Store[];
  count?: number;
}

const StoreList = ({ stores, count }: StoreListProps) => {
  return (
    <div>
      <h3>{count || "-"} Active Stores</h3>
      {stores && stores.map(x => <StoreListItem key={x.storeId} store={x} />)}
    </div>
  );
};

export default StoreList;
