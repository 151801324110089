import React from "react";
import useApi, { Api } from "../api";
import { Warehouse } from "../customers/customer";
import WarehouseList from "./WarehouseList";

interface WarehousesParams {
  customerId: string;
  count: number | undefined;
  defaultWarehouseId: string;
}

const loadData = async (
  api: Api,
  aborted: () => boolean,
  customerId: string,
  setWarehouses: React.Dispatch<Warehouse[]>,
): Promise<void> => {
  const warehouseResponse = await api.warehouses(customerId);

  if (!aborted()) {
    setWarehouses(warehouseResponse.items);
  }
};

const Warehouses = ({ customerId, count, defaultWarehouseId }: WarehousesParams) => {
  const [warehouses, setWarehouses] = React.useState();

  useApi((api, aborted) => loadData(api, aborted, customerId, setWarehouses), [
    customerId,
    setWarehouses,
  ]);

  return (
    <WarehouseList warehouses={warehouses} count={count} defaultWarehouseId={defaultWarehouseId} />
  );
};

export default Warehouses;
