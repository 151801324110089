import React, { ReactNode, ReactElement } from "react";
import styled from "styled-components";
import { Operation } from "../customers/customer";
import { DateTime } from "luxon";
import humanizeDuration from "humanize-duration";
import Tag from "../components/Tag";
import Icon from "../components/Icon";
import { Link } from "react-router-dom";

interface OperationListItemProps {
  operation: Operation;
}

const TableRow = styled.tr`
  td {
    padding-right: 1rem;
  }
`;
const StartedRelative = styled.div``;
const StartedAbsolute = styled.div`
  font-size: 10pt;
  color: #999999;
`;
const ReferenceCell = styled.td`
  font-size: 10pt;
`;

const StatusTag = ({ status }: { status: string }) => {
  if (status === "finished") {
    return (
      <Tag type="success">
        <Icon name="check" />
      </Tag>
    );
  }
  if (status === "failed") {
    return (
      <Tag type="alert">
        <Icon name="times" />
      </Tag>
    );
  }
  if (status === "running") {
    return (
      <Tag type="warning">
        <Icon name="play" />
      </Tag>
    );
  }

  return null;
};

const DurationCell = ({
  startDateTime,
  finished,
}: {
  startDateTime: DateTime;
  finished: string | undefined;
}) => {
  if (!finished) {
    return <td></td>;
  }

  const endDateTime = DateTime.fromISO(finished);

  return (
    <td>
      <StartedRelative>
        {humanizeDuration(endDateTime.diff(startDateTime).as("milliseconds"))}
      </StartedRelative>
      <StartedAbsolute>{endDateTime.toLocaleString(DateTime.DATETIME_MED)}</StartedAbsolute>
    </td>
  );
};

const OperationListItem = ({ operation }: OperationListItemProps) => {
  const startDateTime = DateTime.fromISO(operation.started);
  const ErrorDetails =
    operation.errorMessage || operation.errorCode ? (
      <TableRow key={operation.operationId + "-error"}>
        <td></td>
        <td colSpan={5}>
          {operation.errorCode}: {operation.errorMessage}
        </td>
      </TableRow>
    ) : null;

  console.log(operation);

  return (
    <>
      <TableRow key={operation.operationId}>
        <td>
          <StatusTag status={operation.status} />
        </td>
        <td>
          <Link to={`/events/operation/${operation.operationId}`}>
            <StartedRelative>{startDateTime.toRelative()}</StartedRelative>
          </Link>
          <StartedAbsolute>{startDateTime.toLocaleString(DateTime.DATETIME_MED)}</StartedAbsolute>
        </td>
        <DurationCell startDateTime={startDateTime} finished={operation.finished} />
        <ReferenceCell>{operation.awsReferenceId}</ReferenceCell>
        <td>{operation.orderCount}</td>
      </TableRow>
      {ErrorDetails}
    </>
  );
};
export default OperationListItem;
