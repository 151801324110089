import React from "react";
import WarehouseListItem from "./WarehouseListItem";
import { Warehouse } from "../customers/customer";

interface WarehouseListProps {
  warehouses?: Warehouse[];
  count?: number;
  defaultWarehouseId: string;
}

const WarehouseList = ({ warehouses, count, defaultWarehouseId }: WarehouseListProps) => {
  return (
    <div>
      <h3>{count || "-"} Active Warehouses</h3>
      {warehouses &&
        warehouses.map(x => (
          <WarehouseListItem
            key={x.warehouseId}
            warehouse={x}
            isDefault={x.warehouseId === defaultWarehouseId}
          />
        ))}
    </div>
  );
};

export default WarehouseList;
