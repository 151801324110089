import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useAuth0 } from "../react-auth0-wrapper";
import Icon from "./Icon";

const Nav = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #105b97;
`;

const MainLinks = styled.div`
  font-size: 14pt;
`;

const Header = styled.header`
  margin: 0.8rem 0;
`;

const Footer = styled.footer`
  margin: 1rem 0;
  font-size: 14pt;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 0.3rem 1rem;
  color: white;
  text-decoration: none;
  width: 100%;

  :hover {
    background: #1b3251;
  }

  &.active {
    background-color: #d8b446;
    color: #1b3251;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 0.3rem 1rem;
  color: white;
  text-decoration: none;
  width: 100%;

  :hover {
    background: #1b3251;
  }

  &.active {
    background-color: #d8b446;
    color: #1b3251;
  }
`;

const LogoutButton = styled.button`
  font-size: 14pt;
  display: block;
  padding: 0.3rem 1rem;
  color: white;
  text-decoration: none;
  border: 0;
  width: 100%;
  background: inherit;
  text-align: left;

  :hover {
    background: #1b3251;
  }
`;

const LinkIcon = styled(Icon)`
  min-width: 1.5rem;
`;

const NavBar = () => {
  const { logout } = useAuth0();

  return (
    <Nav>
      <div>
        <Header>
          <StyledLink to="/">
            <img src="/images/shipworks-logo.svg" alt="ShipWorks Logo" />
            <div>Admin</div>
          </StyledLink>
        </Header>
        <MainLinks>
          <StyledNavLink to="/" exact>
            <LinkIcon name="columns" /> Home
          </StyledNavLink>
          <StyledNavLink to="/customers">
            <LinkIcon name="users" /> Customers
          </StyledNavLink>
        </MainLinks>
      </div>

      <Footer>
        <LogoutButton
          onClick={() =>
            logout({ returnTo: `${window.location.protocol}//${window.location.hostname}` })
          }
        >
          <LinkIcon name="sign-out-alt" /> Log Out
        </LogoutButton>
      </Footer>
    </Nav>
  );
};

export default NavBar;
