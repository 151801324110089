import React, { ReactNode, MouseEvent, EventHandler } from "react";
import styled from "styled-components";

interface ActionButtonProps {
  onClick: EventHandler<MouseEvent<HTMLButtonElement>>;
  children: ReactNode;
}

const ButtonContainer = styled.button`
  display: flex;
  font-size: 12pt;
  padding: 0.4rem;
  background-color: #105b97;
  color: white;
  border: 0;
  border-radius: 0.2rem;
  text-decoration: none;

  &[disabled] {
    background-color: #666666;
    color: #ffffff;
  }
`;

const Text = styled.div`
  text-align: left;
  flex-grow: 1;
  padding: 0.2rem 0.4rem;
`;

const ActionButton = ({ onClick, children }: ActionButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Text>{children}</Text>
    </ButtonContainer>
  );
};

export default ActionButton;
