import React from "react";
import styled from "styled-components";
import { Customer } from "./customer";
import CustomerListItem from "./CustomerListItem";
import useApi, { Api } from "../api";
import useDebounce from "../hooks/useDebounce";

const loadData = async (
  api: Api,
  aborted: () => boolean,
  query: string,
  setCustomers: React.Dispatch<Customer[]>,
): Promise<void> => {
  if (!query || query.length === 0) {
    setCustomers([]);
    return;
  }

  const customerResponse = await api.customer.list(query);

  if (!aborted()) {
    setCustomers(customerResponse.items);
  }
};

const emptyCustomers: Customer[] = [];

const Container = styled.div``;
const SearchHeader = styled.div`
  font-size: 14pt;
  color: #666666;
`;
const SearchInput = styled.input`
  font-size: 24pt;
  padding: 0.4rem 0.6rem;
  width: 100%;
  border-radius: 0.2rem;
  border: solid #333333 1px;
`;
const SearchBox = styled.div`
  margin-bottom: 1rem;
`;

const CustomerList = () => {
  const [query, setQuery] = React.useState("");
  const [customers, setCustomers] = React.useState(emptyCustomers);
  const debouncedQuery = useDebounce(query, 500);

  useApi((api, aborted) => loadData(api, aborted, debouncedQuery, setCustomers), [
    debouncedQuery,
    setCustomers,
  ]);

  return (
    <Container>
      <SearchBox>
        <SearchHeader>Customer ID:</SearchHeader>
        <SearchInput type="text" value={query} onChange={x => setQuery(x.target.value)} />
      </SearchBox>

      {customers.map(x => (
        <CustomerListItem key={x.customerId} customer={x} />
      ))}
    </Container>
  );
};

export default CustomerList;
