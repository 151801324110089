import React from "react";
import styled from "styled-components";
import { Customer } from "./customer";
import useApi, { Api } from "../api";
import { Link, RouteComponentProps } from "react-router-dom";
import Stores from "../stores/Stores";
import Warehouses from "../warehouses/Warehouses";
import CustomerDetailsInfo from "./CustomerDetailsInfo";
import BreadCrumbs from "../components/BreadCrumbs";

interface CustomerDetailsParams {
  customerId: string;
}

const Container = styled.div`
  height: 100%;
`;

const Details = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

const loadData = async (
  api: Api,
  aborted: () => boolean,
  customerId: string,
  setCustomer: React.Dispatch<Customer>,
): Promise<void> => {
  const customerResponse = await api.customer.show(customerId);

  if (!aborted()) {
    setCustomer(customerResponse.item);
  }
};

const CustomerListItemDetails = ({ match }: RouteComponentProps<CustomerDetailsParams>) => {
  const customerId = match.params.customerId;
  const [customer, setCustomer] = React.useState<Customer>(Object);

  useApi((api, aborted) => loadData(api, aborted, customerId, setCustomer), [customerId]);

  return (
    <Container>
      <BreadCrumbs>
        <Link to="/customers">customers</Link> / {customerId}
      </BreadCrumbs>
      {customer && <CustomerDetailsInfo customer={customer} />}
      <Details>
        <Stores customerId={customerId} count={customer && customer.stores} />
        <Warehouses
          customerId={customerId}
          defaultWarehouseId={customer && customer.defaultWarehouse}
          count={customer && customer.warehouses}
        />
      </Details>
    </Container>
  );
};

export default CustomerListItemDetails;
