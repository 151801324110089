import React from "react";
import styled from "styled-components";
import { Customer } from "./customer";
import ActionList from "../components/ActionList";
import ActionButton from "../components/ActionButton";
import ActionLink from "../components/ActionLink";

interface CustomerListItemProps {
  customer: Customer;
}

const Container = styled.div`
  display: flex;
  margin: 1rem 0;
`;

const Header = styled.div`
  font-size: 24pt;
  margin: 0 0 1rem 0;
`;

const CustomerId = styled.span`
  display: inline-block;
  margin-right: 1rem;
  padding: 0.2rem 0.6rem;
  background-color: #333333;
  color: #ffffff;
  border-radius: 0.2rem;
`;

const Details = styled.div`
  flex: 1;
`;

const Address = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  font-size: 12pt;
`;

const CustomerListItem = ({ customer }: CustomerListItemProps) => {
  return (
    <>
      <Container>
        <Details className="customerListItemSummary-border">
          <Header>
            <CustomerId>{customer.customerId}</CustomerId>
            <span>
              {customer.firstName} {customer.lastName}
            </span>
          </Header>
          {customer.street1 && (
            <Address>
              <div>{customer.street1}</div>
              <div>
                {customer.city}, {customer.state} {customer.zip}
              </div>
            </Address>
          )}
        </Details>
        <ActionList direction="horizontal">
          <ActionLink to={`/events/customer/${customer.customerId}`}>Events</ActionLink>
          <ActionButton onClick={() => alert("Not implemented yet")}>Disable</ActionButton>
        </ActionList>
      </Container>
    </>
  );
};

export default CustomerListItem;
