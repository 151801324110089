import React from "react";
import { HubEvent } from "../customers/customer";
import styled from "styled-components";

interface EventListItemProps {
  event: HubEvent;
  entityType: "customer" | "operation" | "order" | "product";
}

const TableCell = styled.td`
  padding-right: 1rem;
`;

const EventListItem = ({ event, entityType }: EventListItemProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <tr key={event.sequence}>
        <TableCell>{event.sequence}</TableCell>
        <TableCell>{event.version}</TableCell>
        <TableCell>{event.timestamp}</TableCell>
        <TableCell>{Object.keys(event[entityType])[0]}</TableCell>
        <td>
          <button onClick={() => setExpanded(!expanded)}>toggle</button>
        </td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan={5}>
            <pre>{JSON.stringify(event, null, 2)}</pre>
          </td>
        </tr>
      )}
    </>
  );
};

export default EventListItem;
