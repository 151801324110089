import React from "react";
import styled from "styled-components";
import { Warehouse } from "../customers/customer";
import Tag from "../components/Tag";
import IdList from "../components/IdList";
import Card from "../components/Card";

interface WarehouseListItemProps {
  warehouse: Warehouse;
  isDefault: boolean;
}

const Header = styled.div`
  align-items: baseline;
  justify-content: space-between;
`;
const Name = styled.div`
  font-size: 20pt;
  margin-bottom: 0.5rem;
`;
const Address = styled.div`
  margin-bottom: 0.6rem;
`;
const Line = styled.div``;

const Tags = styled.div``;

const WarehouseListItem = ({ warehouse, isDefault }: WarehouseListItemProps) => {
  return (
    <Card>
      <Header>
        <Name>{warehouse.name}</Name>
      </Header>
      <Address>
        <Line>{warehouse.street}</Line>
        <Line>
          {warehouse.city}, {warehouse.state} {warehouse.zip}
        </Line>
      </Address>

      <IdList
        items={{
          "Warehouse ID": warehouse.warehouseId,
          "ShipWorks Database ID": warehouse.shipworksId || "Not linked",
        }}
      />

      <Tags>
        {warehouse.removed && <Tag type="alert" title="removed" />}
        {isDefault && <Tag type="success" title="default" />}
      </Tags>
      <div></div>
    </Card>
  );
};
export default WarehouseListItem;
